<template>
  <div class="projects mb-4">
    <header class="projects-header">
      <TitleSection
        :title="'Boleterías'"
        :subtitle="'Gestiona las boleterias del sistema'"
      />
    </header>

    <div class="row py-4">
      <div class="col-lg-3 col-md-3 col-sm-12 pb-2 pt-0">
        <v-btn
          :class="'ml-5'"
          @click="openDialog"
          class="zmdi action-btn"
          title="Agregar nueva boletería"
          icon
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
          </svg>
        </v-btn>
      </div>
    </div>

    <TableBoxOffices :assignments="assignments" @openEdit="openEdit">
    </TableBoxOffices>

    <!-- DIALOG FORM -->
    <WDialog
      :dialog="dialog"
      :closeDialog="closeDialog"
      :title="getTitle"
      width="450"
    >
      <template v-slot:form-dialog>
        <FormBoxOffices
          v-if="dialog"
          :assignmentEdited="assignmentEdited"
          @save="saveAssignment"
          @edit="editAssignment"
        />
      </template>
    </WDialog>
  </div>
</template>

<script>
import TitleSection from "@/components/titles/TitleSection";
import TableBoxOffices from "@/components/tables/TableBoxOffices";
import FormBoxOffices from "@/components/forms/FormBoxOffices";
import WDialog from "@/components/dialogs/WindowDialog";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import moment from "moment-timezone";
import axios from "@/api/entradaslima";
import store from "../../store";
export default {
  components: {
    TitleSection,
    TableBoxOffices,
    WDialog,
    FormBoxOffices
  },
  data() {
    return {
      rounded: false,
      color: "#738297",
      bgColor: "#ffffff",
      required: [v => !!v || "Requerido"],
      upToZero: [v => (!!v && v > 0) || "Incorrecto"],
      disabled: false,
      details: true,
      filled: true,
      outlined: false,
      dense: true,
      autocomplete: "off",
      readonly: true,
      dark: false,
      dialog: false,
      loading: false,
      idParque: store.getters.getSessionParqueId,
      usuario: store.getters.getSessionUser.CORREO,
      assignments: [],
      assignmentEdited: null,
      users: []
    };
  },
  computed: {
    getTitle() {
      return this.assignmentEdited ? "Editar Boletería" : "Nueva Boletería";
    },
    getSubtitle() {
      return this.assignmentEdited
        ? "Rellena todos los campos para crear una nueva boletería"
        : "Rellena todos los campos para editar los datos de la boletería";
    }
  },
  async mounted() {
    await this.getBoxOffices();
  },
  methods: {
    ...mapActions({
      generateConfirmDialog: "generateConfirmDialog",
      generateSnackBarMessage: "generateSnackBarMessage"
    }),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    openEdit(item) {
      this.assignmentEdited = { ...item };
      this.dialog = true;
    },
    async getBoxOffices() {
      try {
        this.loading = true;
        const { data } = await axios.get(
          `/parques/${this.idParque}/boleterias`
        );
        this.loading = false;
        this.assignments = data.data;
      } catch (error) {
        this.loading = false;
        return Swal.fire({
          icon: "error",
          text: error.response.data.message || "Ocurrió un error interno"
        });
      }
    },
    async saveAssignment(dto) {
      try {
        const { data } = await axios.post(
          `/parques/${this.idParque}/boleterias`,
          {
            descripcion: dto.boxOffice,
            estado: dto.state.ESTADO,
            usuario: this.usuario
          }
        );
        this.closeDialog();
        this.generateSnackBarMessage({
          message: data.message
        });
        await this.getBoxOffices();
      } catch (error) {
        return Swal.fire({
          icon: "error",
          text: error.response.data.message || "Ocurrió un error interno"
        });
      }
    },
    async editAssignment(dto) {
      try {
        const { data } = await axios.put(
          `/parques/${this.idParque}/boleterias/${dto.IDBOLETERIA}`,
          {
            descripcion: dto.boxOffice,
            estado: dto.state.ESTADO,
            usuario: this.usuario
          }
        );
        this.closeDialog();
        this.generateSnackBarMessage({
          message: data.message
        });
        await this.getBoxOffices();
      } catch (error) {
        return Swal.fire({
          icon: "error",
          text: error.response.data.message || "Ocurrió un error interno"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
