<template>
  <v-form ref="formAssignments" v-on:submit.prevent="saveForm">
    <div class="row mt-4 pt-3">
      <div v-if="errorMessage" class="col-lg-12 col-md-12 col-sm-12 pb-1 pt-0">
        <div class="alert alert-danger py-2" role="alert">
          <small>{{ errorMessage }}</small>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 pb-2 pt-0">
        <v-text-field
          label="Boletería"
          type="text"
          :rounded="rounded"
          :color="color"
          :dark="dark"
          :background-color="bgColor"
          :rules="required"
          :disabled="disabled"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          v-model="assignment.boxOffice"
        >
        </v-text-field>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 pb-2 pt-0">
        <v-autocomplete
          label="Estado"
          :rounded="rounded"
          :color="color"
          :dark="dark"
          :background-color="bgColor"
          :rules="required"
          :disabled="disabled"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          :items="states"
          item-text="DESCRIPCION"
          :multiple="false"
          return-object
          small-chips
          v-model="assignment.state"
        >
        </v-autocomplete>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 pb-4 pt-4 d-flex">
        <v-btn
          type="submit"
          :loading="loading"
          :disabled="loading"
          tile
          dark
          color="primary"
          depressed
          class="ml-auto"
        >
          Guardar
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import config from "./baseConfig";
export default {
  props: {
    assignmentEdited: Object
  },

  data() {
    return {
      ...config,
      //
      upToZero: [v => (!!v && v > 0) || "Incorrecto"],
      //
      errorMessage: "",
      loading: false,
      //
      states: [
        { ESTADO: 1, DESCRIPCION: "ACTIVO" },
        { ESTADO: 0, DESCRIPCION: "INACTIVO" }
      ],
      //
      assignment: this.assignmentEdited
        ? {
            ...this.assignmentEdited,
            boxOffice: this.assignmentEdited.DESCRIPCION,
            state: {
              ESTADO: this.assignmentEdited.ESTADO,
              DESCRIPCION:
                this.assignmentEdited.ESTADO === 1 ? "ACTIVO" : "INACTIVO"
            }
          }
        : {
            boxOffice: null,
            state: 1
          }
    };
  },
  methods: {
    validateForm() {
      return this.$refs.formAssignments.validate();
    },
    saveForm() {
      if (!this.validateForm()) return;
      const assignmentDTO = { ...this.assignment };
      if (this.assignmentEdited) {
        // edited
        this.updateAssignment(assignmentDTO);
      } else {
        // created
        this.saveAssignment(assignmentDTO);
      }
    },
    async saveAssignment(assignmentDTO) {
      await this.$emit("save", assignmentDTO);
    },
    async updateAssignment(assignmentDTO) {
      await this.$emit("edit", assignmentDTO);
    }
  }
};
</script>

<style lang="scss" scoped></style>
