<template>
  <v-data-table
    :headers="headers"
    :items="assignments"
    :items-per-page="10"
    class="elevation-0 projects-table"
  >
    <template v-slot:[`item.DESCRIPCION_ESTADO`]="{ item }">
      <v-chip
        class="mb-0"
        small
        :color="item.ESTADO == '1' ? 'green' : 'red'"
        >{{ item.DESCRIPCION_ESTADO }}</v-chip
      >
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex justify-content-end">
        <CrudButtons
          :noDelete="true"
          @edit="$emit('openEdit', item)"
        ></CrudButtons>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import CrudButtons from "@/components/buttons/CrudButtons";
import moment from "moment-timezone";
export default {
  components: {
    CrudButtons
  },
  props: {
    assignments: Array
  },
  data() {
    return {
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "IDBOLETERIA"
        },
        {
          text: "Boletería",
          sortable: false,
          value: "DESCRIPCION" // cambiar value
        },
        {
          text: "Estado",
          sortable: false,
          value: "DESCRIPCION_ESTADO" // cambiar value
        },
        {
          text: "",
          sortable: false,
          value: "actions"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped></style>
